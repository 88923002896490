const navbarItems = [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About Us",
      link: "/about",
    },
    {
      title: "Services",
      link: "/services",
    },
    {
      title: "Blogs",
      link: "/blogs",
    },
    {
      title:"Health Packages",
      link:"/healthPackages"
    },
    {
      title:"Gallery",
      link:"/gallery"
    },
    {
      title:"Contact Us",
      link:"/contact"
    },
    
  ];
  
  export default navbarItems;
import React from 'react'
import { FaRegCheckCircle } from "react-icons/fa";

const About = () => {
    return (
        <>
            <section id="about" class="about">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h2>About the Doctor</h2>
                        <p>Dr. Yogesh Kadam, an esteemed Diabetologist, boasts an extensive academic background and a wealth of experience in the field of medicine. Graduating with M.D. in Medicine from B.J. Medical College, Pune, in 1996, followed by a DNB in Medicine from the National Board of Examinations, Ministry of Health & Family Welfare, Govt. of India, in November 1997. Dr. Yogesh Kadam has continually demonstrated dedication and expertise throughout his career. From serving as a Lecturer and progressing to the role of Honorary Associate Professor at B.J. Medical College from 2003 to 2022, to being a Recognised Teacher in Medicine at Maharashtra University of Health Sciences since 2006, Dr. Yogesh Kadam has consistently contributed to medical education and patient care. </p>
                    </div>

                    <div class="row">
                        <div class=" col-md-6  d-flex align-items-center">
                            <div class="img">
                                <img src="images/dr.jpg" alt="" class="img-fluid" />
                                <h3 ><b>Dr. Yogesh Kadam</b></h3>
                                <p>
                                <b>Diabetologist</b>
                                <br/>
                                    M.D. (Medicine), M.N.A.M.S.
                                    <br/>
                                    Diplomat National Board of Medicine
                                    
                                </p>
                            </div>
                            
                        </div>
                        <div class="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
                            
                            
                            <p>
                            As a Consultant Diabetologist at Inamdar Multi speciality Hospital since 2013, and as a Panel Consultant at prestigious institutions such as Ruby Hall Clinic and Jehangir Hospital in Pune, he has provided exceptional care to countless patients. Additionally, Dr. Yogesh Kadam serves as the Director and Diabetologist at Poona Diabetes Centre.  His contributions extend beyond clinical practice, as he has served as a Regional Faculty for various courses, including CCGDM, CCEBDM, and CCDR, and has been a speaker at prestigious conferences like Diabetes India 2020. 
            He is Certified as a Specialist by the IDF School of Diabetes and a member of the Endocrine Society USA and is recognized for his expertise in Diabetes Management and has actively participated in numerous Clinical Trials, further advancing the understanding and treatment of diabetes and metabolic disorders. Dr. Yogesh Kadam is also an accomplished researcher, with publications in renowned journals like the International Journal of Pharma Research and Health Sciences in 2018. With a commitment to excellence in patient care, education, and research, Dr. Yogesh Kadam remains at the forefront of medical innovation, ensuring the well-being of his patients and the advancement of medical knowledge.

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            
        </>
    )
}

export default About
